import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SplashTitle from "../components/SplashTitle";
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import ContactUsBar from "../components/ContactUsBar";

export const pageQuery = graphql`
  {
    splashImage: file(relativePath: { eq: "giant-highway-intersection.jpg" }) {
      ...SplashImage
    }
    site {
      siteMetadata {
        defaultLanguage
      }
    }
  }
`;

export default function NeighborhoodInsightsPage({ data }) {
  const intl = useIntl();

  return (
    <Layout
      intl={intl}
      title={intl.formatMessage({ id: "neighborhood-insights.title", defaultMessage: "Harnessing Big Geodata and AI to derive neighborhood trends" })}
    >
      <Seo
        intl={intl}
        title={intl.formatMessage({
          id: "neighborhood-insights.title",
          defaultMessage: "Harnessing Big Geodata and AI to derive neighborhood trends",
        })}
      />
      <SplashTitle
        image={getImage(data.splashImage)}
        subheadline={intl.formatMessage({ id: "neighborhood-insights.subheadline", defaultMessage: "Decision Making in Dynamic Urban Landscapes" })}
      >
        <FormattedMessage id="neighborhood-insights.headline" defaultMessage="Harnessing Big Geodata and AI to derive neighborhood trends" />
      </SplashTitle>
      <main>
        <div className="max-w-2xl lg:max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 px-4 lg:px-8">
          <div className="grid grid-cols-1 items-center gap-y-4 md:gap-y-10 gap-x-16 lg:grid-cols-2">
            <h2 className="text-4xl md:text-5xl leading-tight md:leading-snug font-extrabold tracking-tight text-gray-900">
              {intl.formatMessage(
                {
                  id: "neighborhood-insights.main-section-headline",
                  defaultMessage: "Urban <mark>neighborhood insights.</mark> Anytime. Anywhere.",
                },
                { mark: (text) => <mark>{text}</mark> }
              )}
            </h2>
            <p className="mt-4 text-gray-500 prose-md md:prose-lg">
              <FormattedMessage
                id="neighborhood-insights.description"
                defaultMessage="The ubicube team is working on a powerful Big Geodata enabled technology that is capable of understanding spatial patterns. But what does that actually mean? We use globally available geodata in combination with sophisticated machine learning models to derive neighborhood insights that can help organizations to optimize site selection, spot areas undergoing gentrification or predict urban sprawl patterns."
              />
            </p>
          </div>
          <div className="w-full my-24">
            <h2 className="text-2xl mb-8 md:text-4xl md:mb-12 font-extrabold tracking-tight text-gray-900">
              <FormattedMessage id="neighborhood-insights.how-does-it-work" defaultMessage="How does it work?" />
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/satmappin.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="neighborhood-insights.section-1-headline-1" defaultMessage="Big Geodata" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="neighborhood-insights.section-1-text-1"
                      defaultMessage="We collect satellite data and geotagged information from numerous sources and feed them into our dedicated geospatial intelligence."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/brainai.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="neighborhood-insights.section-1-headline-2" defaultMessage="Machine learning modelling" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="neighborhood-insights.section-1-text-2"
                      defaultMessage="Our machine learning models are trained to estimate economic and demographic indicators in urban neighborhoods based on multi-source geodata."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/info.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="neighborhood-insights.section-1-headline-3" defaultMessage="Neighborhood Insights" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="neighborhood-insights.section-1-text-3"
                      defaultMessage="These indicators are combined and translated into actionable neighborhood insights (e.g. gentrification score) that can be used by our clients for their purposes."
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full my-24"></div>
          <div>
            <h2 className="text-2xl mb-8 md:text-4xl md:mb-12 font-extrabold tracking-tight text-gray-900">
              <FormattedMessage
                id="neighborhood-insights.headline-benefits"
                defaultMessage="Your <mark>benefits</mark>"
                values={{ mark: (text) => <mark>{text}</mark> }}
              />
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/earth_longlat.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="neighborhood-insights.section-2-headline-1" defaultMessage="Comparable" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="neighborhood-insights.section-2-text-1"
                      defaultMessage="We provide urban neighborhood insights that are comparable beyond administrative borders. No more collecting data from numerous providers."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/tachymetre.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="neighborhood-insights.section-2-headline-2" defaultMessage="Timely and accurate" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="neighborhood-insights.section-2-text-2"
                      defaultMessage="No more outdated economic and demographic indicators."
                    />
                  </p>
                </div>
              </div>
              <div className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="w-16 h-16" src="/symbols/trendline.png" alt="" />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                  <h3 className="text-sm font-medium text-gray-900">
                    <FormattedMessage id="neighborhood-insights.section-2-headline-3" defaultMessage="Actionable Predictions" />
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    <FormattedMessage
                      id="neighborhood-insights.section-2-text-3"
                      defaultMessage="Information about how urban neighborhoods most likely will change in the future"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white max-w-2xl lg:max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8 xl:mb-32">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <h2 className="text-3xl font-extrabold text-gray-900">
                <FormattedMessage
                  id="neighborhood-insights.use-cases-headline"
                  defaultMessage="<mark>Use-cases</mark>"
                  values={{ mark: (text) => <mark>{text}</mark> }}
                />
              </h2>
            </div>
            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage id="neighborhood-insights.use-case-1" defaultMessage="Site Selection" />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="neighborhood-insights.use-case-1-text"
                      defaultMessage="With our technology we can deliver granular, internationally comparable and up-to-date socio-demographic and -economic data to improve site selection processes. Check out this video to learn how it works."
                    />
                  </dd>
                </div>
                <div>
                  <dt className="text-lg leading-6 font-medium text-gray-900">
                    <FormattedMessage id="neighborhood-insights.use-case-2" defaultMessage="Identification of Real Estate Investment Opportunities" />
                  </dt>
                  <dd className="mt-2 text-base text-gray-500">
                    <FormattedMessage
                      id="neighborhood-insights.use-case-2-text"
                      defaultMessage="With our technology we can help organizations to anticipate where the next up-and-coming neighborhoods are."
                    />
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto my-20">
          <ContactUsBar intl={intl} />
        </div>
      </main>
    </Layout>
  );
}
